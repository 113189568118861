/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Ayoup
  Author URL: https://ayoup.me
==========================================================================================*/

const mutations = {
  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////

  UPDATE_SIDEBAR_WIDTH(state, width) {
    state.sidebarWidth = width;
  },
  UPDATE_SIDEBAR_ITEMS_MIN(state, val) {
    state.sidebarItemsMin = val;
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val;
  },
  TOGGLE_CONTENT_OVERLAY(state, val) {
    state.bodyOverlay = val;
  },
  TOGGLE_IS_SIDEBAR_ACTIVE(state, value) {
    state.isSidebarActive = value;
  },
  UPDATE_THEME(state, val) {
    state.theme = val;
  },
  UPDATE_WINDOW_BREAKPOINT(state, val) {
    state.breakpoint = val;
  },
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val;
  },
  UPDATE_USER_ROLE(state, val) {
    if (val && val.length > 0) {
      switch (true) {
        case val.includes("ADMIN"):
          state.userRole = "ADMIN";
          break;
        case val.includes("ACCOUNTANT"):
          state.userRole = "ACCOUNTANT";
          break;
        case val.includes("SUBACCOUNTANT"):
          state.userRole = "SUBACCOUNTANT";
          break;
        case val.includes("HOUSEKEEPER"):
          state.userRole = "HOUSEKEEPER";
          break;
        case val.includes("BACKOFFICE"):
          state.userRole = "BACKOFFICE";
          break;
        case val.includes("SUPPORT"):
          state.userRole = "SUPPORT";
          break;
        case !val.includes("HOUSEKEEPER") && val.includes("DRIVER"):
          state.userRole = "DRIVER";
          break;
        case val.includes("CUSTOMER"):
          state.userRole = "CUSTOMER";
          break;
      }
    }

    // localStorage.setItem("userRole", val);
  },
  // UPDATE_STATUS_CHAT(state, value) {
  //     state.AppActiveUser.status = value;
  // },
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
  },
  UPDATE_ME_USER(state, me) {
    state.me = me;
    // window.$chatwoot.setUser(me.login, {
    //   email: me.email,
    //   name: me.login,
    //   phone_number: me.address ? me.address.phone : null,
    // });
    // window.$chatwoot.setCustomAttributes({
    //   customer_phone: me.address ? me.address.phone : null,
    // });
  },
  PROFILE_COMPLITED(state, val) {
    state.isComplited = val;
  },
  USER_NOT_APPROVED(state, val) {
    state.isNotApproved = val;
  },
  CHART_UPDATE(state, chart) {
    for (let key in state.linechart) {
      state.linechart[key].series[0] = {
        name: chart[key].name,
        data: chart[key].COD,
      };
      // for(let key2 in state.linechart[key].series){
      //     state.linechart[key].series[key2].name=chart[key].name
      //     state.linechart[key].series[key2].data=chart[key].COD
      // }
      state.linechart[key].total = chart[key].total.COD;
    }
  },

  // ////////////////////////////////////////////
  // COMPONENT
  // ////////////////////////////////////////////

  // VxAutoSuggest
  UPDATE_STARRED_PAGE(state, payload) {
    // find item index in search list state
    const index = state.navbarSearchAndPinList.data.findIndex(
      (item) => item.index == payload.index
    );
    // update the main list
    state.navbarSearchAndPinList.data[index].highlightAction = payload.val;

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList.data[index]);
    } else {
      // find item index from starred pages
      const index = state.starredPages.findIndex(
        (item) => item.index == payload.index
      );
      // remove item using index
      state.starredPages.splice(index, 1);
    }
  },
  // ////////////////////////////////////////////
  // Navbar
  // ////////////////////////////////////////////

  // The
  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10);
    state.starredPages = list.concat(starredPagesMore);
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp = false;
    let lastItemInStarredLimited = state.starredPages[10];
    const starredPagesLimited = state.starredPages.slice(0, 10);
    state.starredPages = starredPagesLimited.concat(list);

    state.starredPages.slice(0, 10).map((i) => {
      if (list.indexOf(i) > -1) downToUp = true;
    });
    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited);
    }
  },
  // ////////////////////////////////////////////
  // Navbar
  // ////////////////////////////////////////////

  UPDATE_PAGINATION(state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },
};

export default mutations;
