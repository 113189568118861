/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Ayoup
  Author URL: https://ayoup.me
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)

// ACL
import acl from './acl/acl'

// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css';


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'

// Vue Analytics
import VueAnalytics from 'vue-analytics';

Vue.use(VueAnalytics, {
    id: 'UA-193018527-1',
    router
})


// Vuesax Admin Filters
import './filters/filters'


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// axios
import axios from 'axios'
Vue.prototype.$http = axios

// VeeValidate
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

// PrismJS
// import 'prismjs'
// import 'prismjs/themes/prism-tomorrow.css'

// Apollo 
import { createProvider } from './vue-apollo'
const apolloProvider = createProvider({}, { router })

// Feather font icon
require('./assets/css/iconfont.css')


Vue.config.productionTip = false

new Vue({
  router,
  store,
  apolloProvider,
  acl,
  render: h => h(App)
}).$mount('#app')
