import Vue from "vue";
import VueApollo from "vue-apollo";
// import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import { createApolloClient } from "vue-cli-plugin-apollo/graphql-client";
import { InMemoryCache } from "apollo-cache-inmemory";
// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";

// Http endpoint
let APOLLO_URL;
switch (process.env.NODE_ENV) {
  case "production":
    APOLLO_URL = process.env.VUE_APP_GRAPHQL_HTTP_PROD;
    break;
  case "local":
    APOLLO_URL = process.env.VUE_APP_GRAPHQL_HTTP_LOCAL;
    break;
  case "development":
    APOLLO_URL = process.env.VUE_APP_GRAPHQL_HTTP_DEV;
    break;
  default:
    APOLLO_URL = process.env.VUE_APP_GRAPHQL_HTTP_PROD;
    break;
}

const httpEndpoint = APOLLO_URL;

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:3000/graphql',
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  httpLinkOptions: {
    headers: {
      "Apollo-Require-Preflight": "true",
      "X-Request-Canal": "dashboard",
    },
  },
  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  //{ apolloClient, wsClient }
  const { apolloClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  // apolloClient.wsClient = wsClient

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      let UNAUTHENTICATED =
        error?.networkError?.result?.errors[0]?.extensions?.code ==
        "UNAUTHENTICATED";

      if (
        (error.networkError && error.networkError.statusCode === 400) ||
        UNAUTHENTICATED
      ) {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem("userInfo");
        window.location = "/login";
      }
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem("userInfo");
  }
  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    //console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}
