/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Ayoup
  Author URL: https://ayoup.me
==========================================================================================*/

import navbarSearchAndPinList from "@/layouts/components/navbarSearchAndPinList";
import themeConfig from "@/../themeConfig.js";
import colors from "@/../themeConfig.js";
const option = () => {
  return {
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0
      }
    },
    chart: {
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth",
      width: 2.5
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100]
      }
    },
    xaxis: {
      type: "numeric",
      lines: {
        show: false
      },
      axisBorder: {
        show: false
      },
      labels: { show: false }
    },
    yaxis: [
      {
        y: 0,
        offsetX: 0,
        offsetY: 0,
        padding: { left: 0, right: 0 }
      }
    ],
    tooltip: {
      x: { show: false }
    }
  };
};
let KEYS = ["confirmed", "created", "total"];
const chartData = (keys = ["confirmed", "created", "total"]) => {
  let obj = {};
  for (let key of keys) {
    obj[key] = {
      id: key,
      total: 0,
      series: [
        {
          name: key,
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ],
      chartOptions: option()
    };
  }
  //console.log('obj__',obj)
  return obj;
};
const linechart = chartData(KEYS);

const state = {
  linechart,
  isSidebarActive: true,
  breakpoint: null,
  sidebarWidth: "default",
  reduceButton: themeConfig.sidebarCollapsed,
  bodyOverlay: false,
  sidebarItemsMin: false,
  theme: themeConfig.theme || "light",
  navbarSearchAndPinList: navbarSearchAndPinList,
  me: {},
  isComplited: false,
  isNotApproved: false,
  AppActiveUser: {
    id: 0,
    name: "John Doe",
    about:
      "Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.",
    img: "avatar-s-11.png",
    status: "online"
  },

  themePrimaryColor: colors.primary,

  starredPages: navbarSearchAndPinList.data.filter(
    page => page.highlightAction
  ),
  userRole: null,
  pagination: {
    descending: false,
    page: 1,
    rowsPerPage: 30,
    sortBy: null,
    totalItems: 0,
    argument: "status",
    query: null,
    search: null,
    dates: { gte: null, lt: null, bool: false },
    only: {}
  },
  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar component
  windowWidth: null
};

export default state;
