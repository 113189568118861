/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Ayoup
  Author URL: https://ayoup.me
==========================================================================================*/

import Vue from "vue";
import VxTooltip from "./layouts/components/vx-tooltip/VxTooltip.vue";
import VxCard from "./components/vx-card/VxCard.vue";
import vxAlert from "./components/vxAlert/vxAlert.vue";
import VxList from "./components/vx-list/VxList.vue";
import VxBreadcrumb from "./layouts/components/VxBreadcrumb.vue";
import FeatherIcon from "./components/FeatherIcon.vue";
import VxInputGroup from "./components/vx-input-group/VxInputGroup.vue";

import vsComponent from "./components/vxTable/vsTable";
import vsComponent2 from "./components/vxTable/vsTh";
import vsComponent3 from "./components/vxTable/vsTr";
import vsComponent4 from "./components/vxTable/vsTd";
//
import vsCallPopup from "./components/callPopup";
//

import vxSelect from "./components/vxSelect/vsSelect.vue";
import vxSelectItem from "./components/vxSelect/vsSelectItem.vue";
import vxSelectGroup from "./components/vxSelect/vsSelectGroup.vue";
//
Vue.component(VxTooltip.name, VxTooltip);
Vue.component(VxCard.name, VxCard);
Vue.component(vxAlert.name, vxAlert);
Vue.component(VxList.name, VxList);
Vue.component(VxBreadcrumb.name, VxBreadcrumb);
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component(VxInputGroup.name, VxInputGroup);

Vue.component(vsComponent.name, vsComponent);
Vue.component(vsComponent2.name, vsComponent2);
Vue.component(vsComponent3.name, vsComponent3);
Vue.component(vsComponent4.name, vsComponent4);
Vue.component(vsCallPopup.name, vsCallPopup);
//

Vue.component(vxSelect.name, vxSelect);
Vue.component(vxSelectItem.name, vxSelectItem);
Vue.component(vxSelectGroup.name, vxSelectGroup);

// v-select component

import vSelect from "vue-select";

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement =>
      createElement("feather-icon", {
        props: {
          icon: "XIcon",
          svgClasses: "w-4 h-4"
        }
      })
  },
  OpenIndicator: {
    render: createElement =>
      createElement("feather-icon", {
        props: {
          icon: "ChevronDownIcon",
          svgClasses: "w-5 h-5"
        }
      })
  }
});

Vue.component(vSelect);
