<template>
  <div>
    <vs-popup classContent="popup-example" :title="`${info.title}`" :active.sync="isActiveLocal">
      <!-- selectOptions -->
      <!-- {{selected}} -->
      <template v-if="selecter">
        <vx-select
          v-validate="'required'"
          v-model="selected"
          :label="info.title"
          name="crivers"
          class="mb-5"
          autocomplete
        >
          <template v-for="item in selectOptions">
            <vx-select-item :key="item.value" :value="item.value" :text="item.text" />
          </template>
        </vx-select>
      </template>

      <slot name="popBody"></slot>
      <div class="flex">
        <div class="flex-left" v-if="selecter">
          <vs-chip v-if="selectedPickup.length>0" class="mb-4 ml-4 flex items-center">
            Selected Pickup N°:
            {{selectedPickup.length}}
          </vs-chip>
        </div>

        <div class="flex-right" style="margin-left: auto;">
          <vs-button @click="clickHandler" color="primary" type="filled">Save</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  name: "callPopup",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    selectedPickup: {
      type: Array,
      default: () => []
    },
    selectOptions: {
      type: Array,
      default: () => []
    },
    info: {
      type: Object,
      default: () => {}
    },
    selecter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isActiveLocal: {
      get() {
        return this.active;
      },
      set(val) {
        this.$emit("close", val);
        this.initValues();
      }
    }
  },
  data() {
    return {
      selected: null
    };
  },
  methods: {
    initValues() {
      this.selected = null;
    },
    async clickHandler() {
      this.$emit("save", this.selected);
      this.initValues();
    }
  }
};
</script>
        