import Vue from "vue";
import { AclInstaller, AclCreate, AclRule } from "vue-acl";
import router from "@/router";

Vue.use(AclInstaller);

let initialRole = "CUSTOMER";
const user = JSON.parse(localStorage.getItem("userInfo"));
if (user) {
  // initialRole = user.role && Array.isArray(user.role) ? user.role[0] : null;
  switch (true) {
    case user.role.includes("ADMIN"):
      initialRole = "ADMIN";
      break;
    case user.role.includes("HOUSEKEEPER"):
      initialRole = "HOUSEKEEPER";
      break;
    case user.role.includes("BACKOFFICE"):
      initialRole = "BACKOFFICE";
      break;
    case user.role.includes("SUPPORT"):
      initialRole = "SUPPORT";
      break;
    case user.role.includes("SUBDRIVER"):
      initialRole = "SUBDRIVER";
      break;
    case user.role.includes("ACCOUNTANT"):
      initialRole = "ACCOUNTANT";
      break;
    case user.role.includes("SUBACCOUNTANT"):
      initialRole = "SUBACCOUNTANT";
      break;
    case user.role.includes("COMMERCIAL"):
      initialRole = "COMMERCIAL";
      break;
    case !user.role.includes("HOUSEKEEPER") && user.role.includes("DRIVER"):
      initialRole = "DRIVER";
      break;
    case user.role.includes("CUSTOMER"):
      initialRole = "CUSTOMER";
      break;
    case user.role.includes("COLLECTOR"):
      initialRole = "COLLECTOR";
      break;
    // default:
    //   initialRole = "ADMIN";
  }
}

// console.log("initialRole__", initialRole, user);
export default new AclCreate({
  initial: initialRole,
  notfound: "/",
  router,
  acceptLocalRules: true,
  globalRules: {
    admin: new AclRule("ADMIN").generate(),
    customer: new AclRule("CUSTOMER"),
    collector: new AclRule("COLLECTOR"),
    backoffice: new AclRule("BACKOFFICE"),
    support: new AclRule("SUPPORT"),
    house: new AclRule("ADMIN").or("HOUSEKEEPER").or("BACKOFFICE").or("SUPPORT"),
    secret: new AclRule("ADMIN").or("CUSTOMER"),
    public: new AclRule("public")
      .or("ADMIN")
      .or("CUSTOMER")
      .or("HOUSEKEEPER")
      .or("BACKOFFICE")
      .or("SUPPORT")
      .or("DRIVER")
      .generate()
  }
});
